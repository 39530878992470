import {
  BackgroundWithFoldHalfWidthStyled,
  BackgroundWithFoldStyled,
  GrayBackgroundRound,
  NeutralBackground,
  PrimaryBackgroundRound
} from 'site/src/components/atm.background/background.styled'
import { Breadcrumb } from 'site/src/components/atm.breadcrumb/breadcrumb.component'
import {
  BorderRoundWrapper,
  BottomLinkWrapper,
  FlexWrapper,
  OverflowXHiddenWrapper
} from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { SpecialitiesField } from 'site/src/components/legacy/specialities-field.component'
import { ArticleListItem } from 'site/src/components/mol.article-list-item/article-list-item.component'
import { MediaObjectCell } from 'site/src/components/mol.media-object-cell/media-object-cell.component'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, externalPaths, getPathUrl } from 'utils/path'
import { doctorUrlPrefix, getDetailPageUrl, isExternalUrl, normalizeUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import {
  CosmicjsArtigosCientificosConnection,
  CosmicjsEducacaoMedica,
  CosmicjsEspecialidadesConnection,
  CosmicjsRevistasMedicasConnection,
  Query
} from 'site/src/data/graphql/graphql-types'
import { ScientificArticlesTemplateQueryParams } from 'site/src/modules/scientific-articles/scientific-articles-list'
import { Body, Col, Grid, H1, H2, H3, Row, Spacing } from 'atomic'
import { Button } from 'atomic/atm.button/button.component'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { Hbox, LargeSeparator, Separator } from 'atomic/legacy/obj.box'
import { Form } from 'atomic/legacy/obj.form'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { graphql, navigate } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import styled from 'styled-components'

export interface DiagnosticGuide {
  title: string
  slug: string
  iconSrc: string
  kind: 'script' | 'manual'
}

export interface MedicalEducationDetailTemplateProps {
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
  cosmicjsEducacaoMedica: Partial<CosmicjsEducacaoMedica>
  medicalEducationPageCreatorAllCosmicjsRevistasMedicas: Partial<CosmicjsRevistasMedicasConnection>
  allCosmicjsArtigosCientificos: Partial<CosmicjsArtigosCientificosConnection>
  diagnosticGuides: DiagnosticGuide[]
}

const MedicalEducationTemplate: React.FunctionComponent<PageProps<
  MedicalEducationDetailTemplateProps,
  Query
>> = props => {
  const {
    allCosmicjsEspecialidades,
    cosmicjsEducacaoMedica,
    medicalEducationPageCreatorAllCosmicjsRevistasMedicas: allCosmicjsRevistasMedicas,
    allCosmicjsArtigosCientificos,
    diagnosticGuides
  } = props.pageContext

  const urlPaths = props.location.pathname.split('/').filter(str => str.length)
  // url example: "/medico/educacao-medica/:chosenSpecialty/"
  const chosenSpeciality = urlPaths.length > 2 ? urlPaths[urlPaths.length - 1] : ''
  const revista = allCosmicjsRevistasMedicas.edges[0].node
  const specialities = allCosmicjsEspecialidades.edges.map(item => ({
    label: item.node.title,
    value: item.node.slug
  }))
  const specialitiesObject = specialities.reduce((acc, curr) => {
    acc[`/${curr.value}`] = curr.label
    return acc
  }, {})

  const onValueChange = value => {
    navigate(
      `${doctorUrlPrefix}/${getDetailPageUrl(
        props.pageContext.cosmicjsEducacaoMedica.type_slug,
        value.value
      )}`
    )
  }

  const param: ScientificArticlesTemplateQueryParams = chosenSpeciality && {
    especialidade: chosenSpeciality
  }
  const allArticlesPath = `${appPaths.scientificArticles.path.doctorUrl}?${queryString.stringify(
    param
  )}`

  return (
    <IndexLayout location={props.location}>
      <SEO
        socialMedia={{
          canonicalUrl: `${props.data.site.siteMetadata.siteUrl}${props.location.pathname}${props.location.search}`,
          title: cosmicjsEducacaoMedica.metadata.seo.titulo,
          image: cosmicjsEducacaoMedica.metadata.seo.imagem.url,
          imageAlt: cosmicjsEducacaoMedica.metadata.seo.imagemAlternativa,
          description: cosmicjsEducacaoMedica.metadata.seo.descricao
        }}
      />
      <Grid>
        <Row>
          <Col xs>
            <Separator />
            <Breadcrumb addtionalDictionary={specialitiesObject} />
          </Col>
        </Row>
        <Row bottom="xs">
          <Col xs={12} sm={8} md={9} lg={9}>
            <H1>Fleury Med</H1>
            <Separator />
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}>
            <Form id="form-medical-education" onSubmit={null}>
              <SpecialitiesField
                allCosmicjsEspecialidades={allCosmicjsEspecialidades}
                onValueChange={value => onValueChange(value)}
                initialValueSlug={chosenSpeciality}
              />
            </Form>
            <Separator />
          </Col>
        </Row>
      </Grid>
      <ForcedFade show={true}>
        {allCosmicjsArtigosCientificos && allCosmicjsArtigosCientificos.edges.length > 0 && (
          <OverflowXHiddenWrapper>
            <Grid position={'relative'}>
              <BackgroundWithFoldHalfWidthStyled />

              <Row noGutter>
                <Col sm={6} xs={12} gutter>
                  <LargeSeparator />
                  <Row>
                    <Col xs={12}>
                      <H2>{cosmicjsEducacaoMedica.metadata.artigosTitulo}</H2>
                    </Col>
                  </Row>

                  <Row mb={true}>
                    <Col xs={12}>
                      {allCosmicjsArtigosCientificos.edges.map(article => (
                        <Row mb={true} key={article.node.title}>
                          <Col xs={12}>
                            <ArticleListItem
                              title={article.node.title}
                              description={article.node.metadata.descricaoSimples}
                              link={normalizeUrl(
                                `${getPathUrl(appPaths.scientificArticles.path, true)}/${
                                  article.node.slug
                                }`
                              )}
                            />
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Row mb={true}>
                    <Col xs={12}>
                      <Button
                        id="button-articles-medical-education"
                        kind="secondary"
                        to={allArticlesPath}
                      >
                        {cosmicjsEducacaoMedica.metadata.artigosTextoLink}
                      </Button>
                    </Col>
                  </Row>
                  <LargeSeparator />
                </Col>

                <BackgroundWithFoldStyled as={Col} sm={6} xs={12}>
                  <LargeSeparator />
                  <Row mb={true}>
                    <Col xs={12}>
                      <H2 kind="light">{cosmicjsEducacaoMedica.metadata.revistasTitulo}</H2>
                    </Col>
                  </Row>
                  <Row mb={true}>
                    <Col xs={12} md={4}>
                      <LazyLoadImage
                        src={revista.metadata.imagemCapa.imgix_url}
                        fitWidth
                        alt={revista.metadata.imagemAlternativa}
                      />
                      <Separator />
                    </Col>
                    <Col xs={12} mdOffset={0} md={8}>
                      <Row mb>
                        <Col xs>
                          <H3 kind="light" cell>
                            {`Edição ${revista.metadata.edicao}`}
                          </H3>
                          <H3 kind="light" cell>
                            {revista.title}
                          </H3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <Body kind="light">{revista.metadata.descricao}</Body>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row mb={true}>
                    <Col xs={12} md={4}>
                      <Separator />
                      <Button
                        id="button-medical-education-download"
                        kind="secondary"
                        light
                        external
                        target={'_blank'}
                        to={revista.metadata.pdf.url}
                        expanded
                      >
                        {cosmicjsEducacaoMedica.metadata.revistasTextoLinkDownload}
                      </Button>
                    </Col>
                    <Col xs={12} md={4}>
                      <Separator />
                      <Button
                        id="button-medical-education-magazine-link"
                        kind="secondary"
                        light
                        to={appPaths.medicalMagazines.path.doctorUrl}
                        expanded
                      >
                        {cosmicjsEducacaoMedica.metadata.revistasTextoLink}
                      </Button>
                    </Col>
                  </Row>
                  <LargeSeparator />
                </BackgroundWithFoldStyled>
              </Row>
            </Grid>
          </OverflowXHiddenWrapper>
        )}
        {diagnosticGuides && diagnosticGuides.length > 0 && (
          <NeutralBackground>
            <Grid>
              <LargeSeparatorRow />
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <H2>{cosmicjsEducacaoMedica.metadata.guiasDiagnosticosTitulo}</H2>
                  <Body>{cosmicjsEducacaoMedica.metadata.guiasDiagnosticosDescricao}</Body>

                  <Separator />
                  <Button
                    id="button-medical-education-diagnostic-guide-link"
                    kind="secondary"
                    target={'_blank'}
                    to={`${appPaths.diagnosticManual.path.doctorUrl}`}
                  >
                    {cosmicjsEducacaoMedica.metadata.guiasDiagnosticosTextoLink}
                  </Button>
                </Col>
                <Col xs={12} mdOffset={1} md={8} lg={8}>
                  <Row mt>
                    {diagnosticGuides.map((diagnosticGuide, index) => (
                      <Col xs={12} md={6} lg={6} key={diagnosticGuide.title}>
                        <DiagnosticGuideMediaObjectCellWrapper>
                          <MediaObjectCell
                            imgSrc={diagnosticGuide.iconSrc}
                            imgAlt=""
                            title={diagnosticGuide.title}
                            linkText="Ver mais"
                            linkTo={diagnosticGuide.slug}
                            external={isExternalUrl(diagnosticGuide.slug)}
                            id={`media-object-medical-education-${index}`}
                          />
                        </DiagnosticGuideMediaObjectCellWrapper>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <LargeSeparatorRow />
            </Grid>
          </NeutralBackground>
        )}
        <Grid>
          <Row mb>
            <Col xs={12} sm={12} md={12} lg={12}>
              <LargeSeparator />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <PrimaryBackgroundRound>
                <H2 kind="light" cell>
                  {cosmicjsEducacaoMedica.metadata.inovacaoTitulo}
                </H2>
                <Separator />
                <Body kind="light">{cosmicjsEducacaoMedica.metadata.inovacaoDescricao}</Body>
                <BottomLinkWrapper>
                  <Separator />
                  <Button
                    id="button-inovation-medical-education"
                    kind="secondary"
                    light
                    to={externalPaths.research.path}
                    expanded
                    external
                  >
                    {cosmicjsEducacaoMedica.metadata.inovacaoTextoLink}
                  </Button>
                </BottomLinkWrapper>
              </PrimaryBackgroundRound>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <MatchMedia defaultMinWidth="64em">{md => (md ? <Separator /> : <></>)}</MatchMedia>
              <GrayBackgroundRound>
                <Row center="sm" expanded>
                  <Col xs={12} sm={6} md={3} lg={3}>
                    <Hbox>
                      <Hbox.Item>
                        <BorderRoundWrapper>
                          <MatchMedia defaultMinWidth="64em">
                            {md => (
                              <LazyLoadImage
                                src={
                                  cosmicjsEducacaoMedica.metadata.atualizacaoMedicaImagem.imgix_url
                                }
                                alt={
                                  cosmicjsEducacaoMedica.metadata.atualizacaoMedicaImagemAlternativa
                                }
                                aspectRatio={md ? 1 : 1.4}
                                fitWidth
                              />
                            )}
                          </MatchMedia>
                        </BorderRoundWrapper>
                      </Hbox.Item>
                    </Hbox>

                    <Separator />
                  </Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                    <FlexWrapper>
                      <Row>
                        <Col xs>
                          <H2 kind="light" cell>
                            {cosmicjsEducacaoMedica.metadata.atualizacaoMedicaTitulo}
                          </H2>
                          <Separator />
                          <Body kind="light">
                            {cosmicjsEducacaoMedica.metadata.atualizacaoMedicaDescricao}
                          </Body>
                        </Col>
                      </Row>
                      <BottomLinkWrapper>
                        <Row>
                          <Col xs={12} md={6}>
                            <Separator />
                            <Button
                              id="button-update-class-medical-education"
                              kind="secondary"
                              light
                              external
                              expanded
                              to={cosmicjsEducacaoMedica.metadata.atualizacaoMedicaLinkAulas}
                            >
                              {cosmicjsEducacaoMedica.metadata.atualizacaoMedicaTextoLinkAulas}
                            </Button>
                          </Col>
                          <Col xs={12} md={6}>
                            <Separator />
                            <Button
                              id="button-update-education-medical-education"
                              kind="secondary"
                              light
                              expanded
                              external
                              to={
                                cosmicjsEducacaoMedica.metadata.atualizacaoMedicaLinkFleuryEducacao
                              }
                            >
                              {
                                cosmicjsEducacaoMedica.metadata
                                  .atualizacaoMedicaTextoLinkFleuryEducacao
                              }
                            </Button>
                          </Col>
                        </Row>
                      </BottomLinkWrapper>
                    </FlexWrapper>
                  </Col>
                </Row>
              </GrayBackgroundRound>
            </Col>
          </Row>

          <LargeSeparatorRow />
        </Grid>
      </ForcedFade>
    </IndexLayout>
  )
}
export default MedicalEducationTemplate

const DiagnosticGuideMediaObjectCellWrapper = styled.div`
  height: 100%;
  padding-bottom: ${Spacing.Medium};
`

export const query = graphql`
  query MedicalEducationTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
