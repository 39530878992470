import { BodySecondary, Button, H3 } from 'atomic'
import * as React from 'react'
import { ArticleListItemBodyWrapperStyled } from './article-list-item.style'

interface ArticleListItemProps {
  title: string
  description: string
  link: string
  linkText?: string
}

export const ArticleListItem: React.FunctionComponent<ArticleListItemProps> = props => {
  return (
    <>
      <H3>{props.title}</H3>
      <ArticleListItemBodyWrapperStyled>
        <BodySecondary>
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </BodySecondary>
      </ArticleListItemBodyWrapperStyled>
      <div>
        <Button id="button-article-list-item" kind="link" to={props.link}>
          {props.linkText}
        </Button>
      </div>
    </>
  )
}

ArticleListItem.defaultProps = {
  linkText: 'Ler artigo completo'
}
