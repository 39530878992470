import { Button, H3, Separator, Body } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import {
  MediaObjectCellContentWrapper,
  MediaObjectCellImgWrapper,
  MediaObjectCellStyled,
  MediaObjectCellLinkWrapper
} from './media-object-cell.component.style'
import { Link } from 'gatsby'

export interface MediaObjectCell {
  imgSrc: string
  imgAlt: string
  title: string
  bodyHTML?: string
  linkText?: string
  linkTo: string
  external?: boolean
  id: string
}
export const imgSize = 76
export const MediaObjectCell: React.FunctionComponent<MediaObjectCell> = ({ id, ...props }) => {
  return props.external ? (
    <a id={`anchor-${id}`} href={props.linkTo} role="button">
      <Content id={id} {...props} />
    </a>
  ) : (
    <Link id={`anchor-${id}`} to={props.linkTo}>
      <Content id={id} {...props} />
    </Link>
  )
}

const Content: React.FunctionComponent<MediaObjectCell> = props => (
  <MediaObjectCellStyled>
    <MediaObjectCellImgWrapper>
      <LazyLoadImage src={props.imgSrc} width={imgSize} height={imgSize} alt={props.imgAlt} />
    </MediaObjectCellImgWrapper>

    <MediaObjectCellContentWrapper>
      <H3 cell>{props.title}</H3>
      {props.bodyHTML && (
        <>
          <Separator />
          <Body>
            <div dangerouslySetInnerHTML={{ __html: props.bodyHTML }} />
          </Body>
        </>
      )}

      {props.linkText && (
        <>
          <Separator />
          <MediaObjectCellLinkWrapper>
            <Button id="button-media-object-cell" kind="link">
              {props.linkText}
            </Button>
          </MediaObjectCellLinkWrapper>
        </>
      )}
    </MediaObjectCellContentWrapper>
  </MediaObjectCellStyled>
)
