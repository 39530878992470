import { Border, Color, Spacing } from 'atomic'
import ColorFunc from 'color'
import styled from 'styled-components'

export const MediaObjectCellStyled = styled.div`
  border-radius: ${Border.Radius};
  box-shadow: 0px 4px 8px ${Color.Gray};
  height: 100%;
  overflow: auto;
  background-color: ${Color.White};
`
export const MediaObjectCellContentWrapper = styled.div`
  margin: ${Spacing.Medium};
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const MediaObjectCellLinkWrapper = styled.div`
  flex: 1;
`

export const MediaObjectCellImgWrapper = styled.div`
  float: left;
  margin: ${Spacing.Medium};
  border-radius: ${Border.Radius};
  overflow: hidden;
  background-color: ${props =>
    ColorFunc(props.theme.color.primary)
      .alpha(0.1)
      .hsl()
      .string()};
`
